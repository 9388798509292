import { Container, Grid, TextField, Typography, withStyles } from "@material-ui/core"
import saga from "./saga"
import { budgetActions } from "./reducer"
import CustomField from "commons/components/CustomField"
import Parrafo from "commons/components/Parrafo"
import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router"
import AssistsTable from "./components/AssistsTable"
import BudgetCostsSummary from "./components/BudgetCostsSummary"
import BudgetOptionButtons from "./components/BudgetOptionButtons"
import LaborsSearcher from "./components/LaborsSearcher"
import LaborsTable from "./components/LaborsTable"
import SparePartsSearcher from "./components/SparePartsSearcher"
import SparePartsTable from "./components/SparePartsTable"
import WorkshopSearcher from "./components/WorkshopSearcher"
import WorkshopTable from "./components/WorkshopTable"
import { composedComponent } from "utils/functions"
import { appActions } from "commons/reducer"
import DatePicker from "commons/components/DatePicker"
import CustomNumberField from "commons/components/CustomNumberField"
import styles from "./styles"
import ContractHeader from "commons/components/ContractHeader"

const Budget = (props) => {
  const {
    classes,
    actions,
    controls,
    match: {
      params: { id },
    },
  } = props

  // Id de las manos de obra relacionadas con el tipo taller de trabajo.
  const workshopLaborId = 12

  const initState = {
    serviceId: id,
  }

  const history = useHistory()
  const location = useLocation()
  const [state, setState] = useState(initState)

  const isEditPage = location.pathname.split("/").slice(-1)[0] === "edit"
  const vehicleTypeId = props.controls.details.vehicle?.vehicleTypeId

  useEffect(() => {
    if (!isEditPage) {
      props.actions.resetQuotationInfo()
      props.actions.getService({ id: id })
      props.actions.getSparePartsCosts({ vehicleTypeId })
      props.actions.getLaborCosts({ vehicleTypeId })
    } else {
      props.actions.getBudget({ id: id })
      props.actions.getSparePartsCosts({ vehicleTypeId })
      props.actions.getLaborCosts({ vehicleTypeId })
    }
  }, [id, vehicleTypeId])

  const workshopLabors = props.controls.laborCosts.filter(
    (laborCost) => laborCost.laborTypeId === workshopLaborId
  )

  const filteredLabors = props.controls.laborCosts.filter(
    (laborCost) => laborCost.laborTypeId !== workshopLaborId
  )

  const budgetWorkshopLabors = props.controls.budgetLabors.filter(
    (budgetLabor) => budgetLabor.laborTypeId === workshopLaborId
  )

  const budgetLaborsFiltered = props.controls.budgetLabors.filter(
    (budgetLabor) => budgetLabor.laborTypeId !== workshopLaborId
  )

  const addBudgetSparePart = (budgetSparePart) => {
    const [spareByVehicleType] = budgetSparePart.sparePartCostByVehicleType

    props.actions.addBudgetSparePart({
      ...budgetSparePart,
      quantity: 1,
      total: spareByVehicleType.price,
    })
  }

  const updateBudgetSparePart = (updateObject) => {
    props.actions.updateSparePartInBudget(updateObject)
  }

  const removeBudgetSparePart = (id) => {
    props.actions.removeSparePartInBudget({ id: id })
  }

  const addBudgetLabor = (budgetLabor) => {
    const [laborByVehicleType] = budgetLabor.laborCostByVehicleType

    props.actions.addBudgetLabor({ ...budgetLabor, quantity: 1, total: laborByVehicleType.price })
  }

  const addBudgetWorkshop = (budgetLabor) => {
    const [laborByVehicleType] = budgetLabor.laborCostByVehicleType

    props.actions.addBudgetLabor({
      ...budgetLabor,
      quantity: 1,
      total: laborByVehicleType.price,
      isWorkshop: true,
    })
  }

  const removeBudgetLabor = (budgetLaborId) => {
    props.actions.removeBudgetLabor({ id: budgetLaborId })
  }

  const updateBudgetLabor = (updateLaborObject) => {
    props.actions.updateBudgetLabor(updateLaborObject)
  }

  const updateQuotationAssistsInfo = (updateAssistInfoObject) => {
    props.actions.updateAssistsInfo(updateAssistInfoObject)
  }
  const handleChange = (event) => {
    const object = {}
    object[event.target.id] = event.target.value
    props.actions.updateQuotationInfo(object)
  }

  const objectHasEmptyValues = (object) => {
    const hasEmptyValues = object.some((item) => item === undefined || item === "" || item === null)
    return hasEmptyValues
  }

  const handleCreateBudget = (saveStatus, callback) => {
    let budgetToStore = {
      serviceId: isEditPage ? props.controls.details.id : Number(id),
      cotizationNumber: Number(props.controls.quotationInfo?.cotization_number)
        ? Number(props.controls.quotationInfo.cotization_number)
        : null,
      sapNumber: Number(props.controls.quotationInfo?.cotization_number)
        ? Number(props.controls.quotationInfo.cotization_number)
        : null,
      total: props.controls.quotationInfo.total,
      date: props.controls.quotationInfo.date,
      vehicle_brand: props.controls.quotationInfo.vehicle_brand,
      scheduler_name: props.controls.quotationInfo.scheduler_name,
      repairment_days: Number(props.controls.quotationInfo?.repairment_days)
        ? Number(props.controls.quotationInfo.repairment_days)
        : null,
      order_number: Number(props.controls.quotationInfo?.order_number)
        ? Number(props.controls.quotationInfo.order_number)
        : null,
      // assists_number: Number(props.controls.quotationInfo?.assists?.quantity)
      //   ? Number(props.controls.quotationInfo.assists.quantity)
      //   : 0,
      date_added: props.controls.quotationInfo.date_added,
      net_value: props.controls.quotationInfo?.net_value
        ? Number(props.controls.quotationInfo.net_value)
        : 0,
      vat: props.controls.quotationInfo?.vat ? Number(props.controls.quotationInfo.vat) : 0,
      save_status: saveStatus,
    }
    if (props.controls.details.mileage){
      budgetToStore.mileage = props.controls.details.mileage
    }

    const budgetLaborsToStore = props.controls.budgetLabors.map((budgetLabor) => ({
      laborCostId: budgetLabor.id,
      price: budgetLabor.total,
      quantity: budgetLabor.quantity,
      manHours: budgetLabor?.laborCostByVehicleType[0]?.manHours,
    }))

    const budgetSparePartsToStore = props.controls.budgetSpareParts.map((budgetSparePart) => ({
      sparePartsCostId: budgetSparePart.id,
      price: budgetSparePart.total,
      quantity: budgetSparePart.quantity,
    }))

    let modifiedBudget = { ...budgetToStore };
    if (!vehicleTypeId) {
      const { sapNumber, vehicle_brand, ...rest } = budgetToStore;
      modifiedBudget = { ...rest };
    }
    if (saveStatus === "Confirmado" && objectHasEmptyValues(Object.values(modifiedBudget))) {
      props.actions.setError({
        msg: "No se puede confirmar una cotización con información vacía o mal ingresada",
      })
    } else {
      if (isEditPage) {
        props.actions.updateBudget({
          budgetToStore,
          id,
          budgetLaborsToStore,
          budgetSparePartsToStore,
          history,
        })
      } else {
        props.actions.createBudget({
          budgetToStore,
          budgetLaborsToStore,
          budgetSparePartsToStore,
          history,
          id,
        })
      }
    }
    if (callback) callback();
  }

  const handleCancelBudget = () => {
    if (isEditPage) {
      history.push("/budgets/")
    } else {
      history.push(`/service/${id}`)
    }
  }

  let quotationSparePartsTotalValue = 0
  let quotationLaborsTotalValue = 0

  if (props.controls.budgetSpareParts.length) {
    if (props.controls.budgetSpareParts.length === 1) {
      quotationSparePartsTotalValue = props.controls.budgetSpareParts[0].total
    } else {
      quotationSparePartsTotalValue = props.controls.budgetSpareParts.reduce(
        (acc, current) => acc + current.total,
        0
      )
    }
  }

  if (props.controls.budgetLabors.length) {
    if (props.controls.budgetLabors.length === 1) {
      quotationLaborsTotalValue = props.controls.budgetLabors[0].total
    } else {
      quotationLaborsTotalValue = props.controls.budgetLabors.reduce(
        (acc, current) => acc + current.total,
        0
      )
    }
  }
  let quotationTotalValue = quotationSparePartsTotalValue + quotationLaborsTotalValue

  useEffect(() => {
    props.actions.updateQuotationInfo({
      net_value: quotationTotalValue,
      vat: Math.round(quotationTotalValue * 0.19),
      total: Math.round(quotationTotalValue * 1.19),
    })
  }, [quotationTotalValue])

  return (
    <Container className={classes.container} maxWidth={false}>
      <ContractHeader />
      <Grid container justifyContent="center" alignItems="center" style={{ margin: 0 }}>
        <Grid item md={12} lg={12} xs={12}>
          <Typography variant="h3" component="h3" align="center" style={{ marginTop: 30 }}>
            {isEditPage ? "Editar Cotización" : "Crear cotización"}
          </Typography>
        </Grid>
        <Grid item md={6} lg={6} sm={8} xs={10}>
          <Grid container spacing={2} style={{ "margin-top": "10px" }}>
            <Grid item sm={6} xs={6} container alignItems="flex-end" justifyContent="flex-end">
              <CustomNumberField
                variant={"outlined"}
                className={classes.field}
                label={"Número de Cotización"}
                key={"cotization_number"}
                id={"cotization_number"}
                name={"cotization_number"}
                type={"text"}
                value={props.controls.quotationInfo.cotization_number || ""}
                onHandleChange={handleChange}
              />
            </Grid>
            <Grid item sm={6} xs={6}>
              <TextField
                value=""
                id="scheduler_name"
                label="Agendador"
                variant="outlined"
                fullWidth
                size="medium"
                value={props.controls.quotationInfo.scheduler_name || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={6} xs={6} container alignItems="center" justifyContent="flex-end">
              <TextField
                variant="outlined"
                fullWidth
                size="medium"
                disabled={true}
                value={props.controls.details.vehicle?.plateNumber || ""}
                label="Vehículo"
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={6} xs={6}>
              <DatePicker
                variant="outlined"
                onChange={handleChange}
                id={"date_service_created"}
                name={"date_service_created"}
                label={"Fecha de Agendamiento"}
                value={props.controls.details?.date}
                disableInput={true}
                error={false}
                helperText={""}
                fullWidth={true}
              />
            </Grid>
            <Grid item sm={6} xs={6} container alignItems="center" justifyContent="flex-end">
              <TextField
                id="vehicle_brand"
                value={props.controls.quotationInfo.vehicle_brand || ""}
                label="Marca"
                variant="outlined"
                size="medium"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={6} xs={6}>
              <DatePicker
                variant="outlined"
                /* className={classes.field} */
                onChange={handleChange}
                id={"date_added"}
                name={"date_added"}
                label={"Fecha Ingreso"}
                value={
                  props.controls.quotationInfo.date_added
                    ? props.controls.quotationInfo.date_added
                    : ""
                }
                error={false}
                helperText={""}
                fullWidth={true}
              />
            </Grid>
            <Grid item sm={6} xs={6} container alignItems="center" justifyContent="flex-end">
              <TextField
                id="mileage"
                disabled={true}
                value={props.controls.details.mileage || ""}
                label="Kilometraje"
                variant="outlined"
                size="medium"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={6} xs={6}>
              <DatePicker
                variant="outlined"
                /* className={classes.field} */
                onChange={handleChange}
                id={"date"}
                name={"date"}
                label={"Fecha Cotización"}
                value={props.controls.quotationInfo.date ? props.controls.quotationInfo.date : ""}
                error={false}
                helperText={""}
                fullWidth={true}
              />
            </Grid>
            <Grid item sm={6} xs={6} container alignItems="flex-end" justifyContent="flex-end">
              <CustomNumberField
                variant={"outlined"}
                className={classes.field}
                label={"Días hábiles reparación"}
                key={"repairment_days"}
                id={"repairment_days"}
                name={"repairment_days"}
                type={"text"}
                value={props.controls.quotationInfo.repairment_days || ""}
                onHandleChange={handleChange}
              />
            </Grid>
            <Grid item sm={6} xs={6}>
              <CustomNumberField
                variant={"outlined"}
                className={classes.field}
                label={"Número de Orden"}
                key={"order_number"}
                id={"order_number"}
                name={"order_number"}
                type={"text"}
                value={props.controls.quotationInfo.order_number || ""}
                onHandleChange={handleChange}
              />
            </Grid>
            <Grid item sm={6} xs={6} container alignItems="center" justifyContent="flex-end">
              <TextField
                disabled={true}
                variant="outlined"
                fullWidth
                size="medium"
                value={props.controls.details.doe || ""}
                label="DOE"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={10} md={10} lg={10} xs={12} style={{ marginTop: 30 }}>
          <Typography style={{ fontWeight: "bold" }}>Servicios Solicitados</Typography>
          <CustomField
            variant="outlined"
            key="commentary"
            id="commentary"
            multiline
            rows={5}
            rowsMax={5}
            value={props.controls.details?.description || ""}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item sm={10} md={10} lg={10} xs={12} style={{ marginTop: 30 }}>
          <LaborsSearcher laborCosts={filteredLabors} addBudgetLabor={addBudgetLabor} />
          <LaborsTable
            laborsSelected={budgetLaborsFiltered}
            removeBudgetLabor={removeBudgetLabor}
          />
        </Grid>
        {/* <Grid item md={10} lg={10} xs={10} style={{ marginTop: 30 }}>
          <AssistsTable
            updateQuotationAssistsInfo={updateQuotationAssistsInfo}
            assistsQuantity={props.controls.quotationInfo.assists.quantity}
          />
        </Grid> */}
        <Grid item sm={10} md={10} lg={10} xs={12} style={{ marginTop: 30 }}>
          <WorkshopSearcher workshopCosts={workshopLabors} addBudgetWorkshop={addBudgetWorkshop} />
          <WorkshopTable
            workshopLabors={budgetWorkshopLabors}
            updateBudgetLabor={updateBudgetLabor}
            removeBudgetLabor={removeBudgetLabor}
          />
        </Grid>
        <Grid item sm={10} md={10} lg={10} xs={12} style={{ marginTop: 30 }}>
          <SparePartsSearcher
            sparePartsCosts={props.controls.sparePartsCosts}
            addBudgetSparePart={addBudgetSparePart}
          />
          <SparePartsTable
            sparePartsSelected={props.controls.budgetSpareParts}
            updateBudgetSparePart={updateBudgetSparePart}
            removeBudgetSparePart={removeBudgetSparePart}
          />
        </Grid>
        <Grid item sm={10} md={10} lg={10} xs={12} style={{ marginTop: 30 }}>
          <BudgetCostsSummary
            quotationLaborsTotalValue={quotationLaborsTotalValue}
            quotationSparePartsTotalValue={quotationSparePartsTotalValue}
            // quotationsAssistsTotalValue={props.controls.quotationInfo.assists.total}
          ></BudgetCostsSummary>
        </Grid>
        <Grid item sm={8} md={8} lg={6} xs={8} style={{ marginTop: 30 }}>
          <BudgetOptionButtons
            handleCreateBudget={handleCreateBudget}
            handleCancelBudget={handleCancelBudget}
            propsDetail={props}
            className={{
              buttonsContainer: classes.buttonsContainer,
            }}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

export default composedComponent(Budget, saga, {
  saga: "sagaBudget",
  middlewares: [withStyles(styles)],
  states: ["budget.controls"],
  actions: [budgetActions, appActions],
})
