import { CardMedia, Typography } from "@material-ui/core"
import CirculeBadge from "commons/components/CirculeBadge"
import React from "react"
import { capitalize, fCurrency, fDateUtc, fNumber } from "utils/functions"
import CustomDateGrid from "commons/components/CustomDateGrid"
import BudgetsFilter from "../Filters"
import TableActions from "./Actions"
import Skeleton from "@material-ui/lab/Skeleton"

const BudgetsTable = (props) => {
  const { className, data, onFilter, loading, changeFilter, filters, clearFilters } = props

  const getStateColor = (state) => {
    switch (state) {
      case "Confirmado":
        return "success"
        break
      case "Borrador":
        return "hold"
        break
      default:
        return "hold"
        break
    }
  }

  const columns = [
    {
      field: "cotizationNumber",
      headerName: "N° Cotización",
      headerClassName: "super-app-theme--header",
      flex: 0.75,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">
            <Typography variant="body1" className={className.p}>
              {params?.row?.cotizationNumber ? `${params?.row?.cotizationNumber}` : "--"}
            </Typography>
          </div>
        </Typography>
      ),
    },
    {
      field: "scheduler_name",
      headerName: "Solicitante",
      headerClassName: "super-app-theme--header",
      flex: 0.75,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">
            {params.row?.scheduler_name ? params.row.scheduler_name : "--"}
          </div>
        </Typography>
      ),
    },
    {
      field: "vehicle_brand",
      headerName: "Marca vehículo",
      headerClassName: "super-app-theme--header",
      flex: 0.75,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">
            {params.row?.vehicle_brand ? params.row?.vehicle_brand : "--"}
          </div>
        </Typography>
      ),
    },
    {
      field: "vehicle_model",
      headerName: "Modelo vehículo",
      headerClassName: "super-app-theme--header",
      flex: 0.75,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">
            {params.row?.service?.vehicle?.model ? params.row?.service.vehicle.model : "--"}
          </div>
        </Typography>
      ),
    },
    {
      field: "quotation_date",
      headerName: "Fecha cotización",
      headerClassName: "super-app-theme--header",
      flex: 0.75,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">
            <p>{params.getValue("date") ? fDateUtc(params.getValue("date")) : "--"}</p>
          </div>
        </Typography>
      ),
    },
    {
      field: "status",
      headerName: "Estado guardado",
      headerClassName: "super-app-theme--header",
      flex: 0.75,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">
            <CirculeBadge type={getStateColor(params.getValue("save_status"))} />
            <Typography variant="body1">{params.getValue("save_status")}</Typography>
          </div>
        </Typography>
      ),
    },
    {
      field: "Actions",
      headerName: "Acciones",
      headerClassName: "super-app-theme--header",
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography component="div">
          <div className="flex-section">
            <TableActions status={params.row?.save_status} id={params.row?.id} />
          </div>
        </Typography>
      ),
      flex: 0.75,
    },
  ]

  return (
    <>
      <BudgetsFilter
        onFilter={onFilter}
        changeFilter={changeFilter}
        className={{
          button: className.button,
          clearFiltersButton: className.clearFiltersButton,
          containerFilter: className.containerFilter,
          field: className.field,
          formControl: className.formControl,
        }}
        filters={filters}
        clearFilters={clearFilters}
      ></BudgetsFilter>
      {loading ? (
        <Skeleton variant="rect" height={"400px"} />
      ) : (
        <CustomDateGrid rows={data} columns={columns} maxRows={8} />
      )}
    </>
  )
}

export default BudgetsTable
