import { Button, Grid } from "@material-ui/core"
import ButtonWithLoader from "commons/containers/ButtonWithLoader";
import React from "react"
import { useState } from "react";


const BudgetOptionButtons = (props) => {
  const { handleCreateBudget, propsDetail, handleCancelBudget, className } = props

  const [isLoading, setIsLoading] = useState(false);
 
  const handleButtonClick = async (actionType) => {
    if (isLoading) return;
    setIsLoading(true);
    handleCreateBudget(actionType, () => setIsLoading(false));
  };

  return (
    <Grid container spacing={2} className={className.buttonsContainer}>
      <Grid item sm={4} xs={4} container alignItems="center" justifyContent="center">
        <Button
          variant="outlined"
          style={{ color: "red", borderColor: "red" }}
          onClick={() => handleCancelBudget()}
        >
          Cancelar
        </Button>
      </Grid>
      <Grid item sm={4} xs={4} container alignItems="center" justifyContent="center">
        <ButtonWithLoader
          variant="contained"
          style={{
            color: "white",
            borderColor: "#185517",
            backgroundColor: (propsDetail.controls.loading || isLoading) ? "#9e9e9e" : "#185517"
            }}
          loading={propsDetail.controls.loading || isLoading}
          onClick={() => handleButtonClick("Borrador")}
          disabled={propsDetail.controls.loading || isLoading}
        >
          Guardar como borrador
        </ButtonWithLoader>
      </Grid>
      <Grid item sm={4} xs={4} container alignItems="center" justifyContent="center">
        <ButtonWithLoader
          variant="contained"
          style={{
            color: "white",
            borderColor: "#185517",
            backgroundColor: (propsDetail.controls.loading || isLoading) ? "#9e9e9e" : "#185517"
            }}
          loading={propsDetail.controls.loading || isLoading}
          onClick={() => handleButtonClick("Confirmado")}
          disabled={propsDetail.controls.loading || isLoading}
        >
          Confirmar
        </ButtonWithLoader>
      </Grid>
    </Grid>
  )
}

export default BudgetOptionButtons
